(function($){
	$('body').click(function(e){
		if ($(window).width() < 992) {
			var x = e.clientX;
			var offset = $('nav').offset();
			var w = $('nav').outerWidth();

			if (offset.left >= 0) {
				if (x > w) $('nav').removeClass('appear');
			}
		}
	});

	$('.hamburger').click(function(e){
		$('nav').toggleClass('appear');
	});

	$('.show-search').click(function(e){
		window.location.href = '/search-form';
	});

	$.fn.currentNav = function()
	{
		$('nav .main-menu li.active').removeClass('active');
		$(this).addClass('active');
	}

	$('[data-image]').each(function(idx){
		$(this).css('background-image', 'url(' + $(this).attr('data-image') + ')');
	});

	$('#searchingModal').modal({
		backdrop: 'static',
		keyboard: false,
		show: false
	});


	$('.box-search > form, .box-search-mo > form').on('submit', function(e){
		var keyword = $(this).find('input[name="keyword"]').val();
		if (keyword == "" || keyword === undefined) return false;

		$('#searchingModal').modal('show');
	});
}(jQuery));